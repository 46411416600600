<template>
  <div class="land-trust">
    <HeTong ref="hetong" v-if="showHt" :currentCode="currentCode" :currentItem="currentItem" />
    <div class="quick-banner">
      <img src="../../assets/imgs/banner2.png" alt="" />
    </div>
    <div class="land-main">
      <div class="new-list">
        <ul class="news-tab">
          <li v-for="item of newTab" :key="item.code" :class="item.code == currentCode ? 'active' : ''"
            @click="tabHandle(item)">
            {{ item.name }}
          </li>
        </ul>
        <div class="search">
          <FormQuery v-if="currentCode == 1" ref="formquery1" :data="Query" :key="keyForm" @submit="search"
            @reset="reset" @change="areaChange">
          </FormQuery>
          <FormQuery v-if="currentCode == 2" ref="formquery2" :data="Query2" :key="keyForm" @submit="search2"
            @reset="reset2" @change="zwChange">
          </FormQuery>
        </div>
        <div class="new-des">
          <el-table v-show="currentCode == 1" :data="tableData" style="width: 100%">
            <el-table-column prop="companyName" label="农服商名称" width="200">
            </el-table-column>
            <el-table-column prop="serviceScope" label="作物类型" width="200">
              <template slot-scope="scope">
                <div class="text-container">
                  <span class="two-line-ellipsis" :title="scope.row.serviceScope">{{ scope.row.serviceScope }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="serviceType" label="服务类型" width="200">
              <template slot-scope="scope">
                <span>{{
                  scope.row.serviceType == 1 ? "土地托管" : "社会化服务"
                  }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="intentionRegion" label="意向区域" width="200">
            </el-table-column>
            <el-table-column prop="serviceArea" label="可接管面积" width="200">
            </el-table-column>
            <el-table-column prop="serviceAreaOlde" label="已接管面积" width="200">
            </el-table-column>
            <el-table-column prop="createTime" label="发布时间">
            </el-table-column>
            <!-- <el-table-column prop="orderState" label="托管状态">
              <template slot-scope="scope">
                <span v-if="scope.row.orderState == '00'">未托管</span>
                <span v-if="scope.row.orderState == '01'">托管中</span>
                <span v-else>托管结束</span>
              </template>
            </el-table-column> -->
            <el-table-column label="操作" width="80">
              <template slot-scope="scope">
                <el-link @click="showDetail(1, scope.row)" type="primary">详情</el-link>
              </template>
            </el-table-column>
          </el-table>

          <el-table v-show="currentCode == 2" :data="tableData1" style="width: 100%">
            <el-table-column prop="areaName" label="区域" width="180">
            </el-table-column>
            <el-table-column prop="orderType" label="托管类型">
              <template slot-scope="scope">
                <span>{{
                  scope.row.orderType == 1 ? "土地托管" : "社会化服务"
                  }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="orderContent" label="托管环节">
              <template slot-scope="scope">
                <div class="text-container">
                  <span class="two-line-ellipsis" :title="scope.row.orderContent">{{ scope.row.orderContent }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="cropCode" label="作物类型">
              <template slot-scope="scope">
                <div class="text-container">
                  <span class="two-line-ellipsis" :title="scope.row.cropCode">{{
                    scope.row.cropCode
                    }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="operscope" label="作物类型">
              <template slot-scope="scope">
                <div class="text-container">
                  <span class="two-line-ellipsis" :title="scope.row.cropCode">{{
                    scope.row.cropCode
                    }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="farmerName" label="托管人" width="140">
              <template slot-scope="scope">
                {{
                scope.row.farmerName.substr(0, 1) +
                (scope.row.sex == 2 ? "女士" : "先生")
                }}
              </template>
            </el-table-column>
            <el-table-column prop="landArea" label="面积(亩)" width="140">
            </el-table-column>
            <el-table-column prop="estimatePrice" label="价格（元）" width="180">
            </el-table-column>
            <el-table-column prop="startDate" label="开始时间">
              <template slot-scope="scope">
                {{
                scope.row.startDate ? scope.row.startDate.substr(0, 10) : ""
                }}
              </template>
            </el-table-column>
            <el-table-column prop="endDate" label="结束时间">
              <template slot-scope="scope">
                {{ scope.row.endDate ? scope.row.endDate.substr(0, 10) : "" }}
              </template>
            </el-table-column>
            <el-table-column prop="orderState" label="托管状态">
              <template slot-scope="scope">
                <span v-if="scope.row.orderState == '00'">未托管</span>
                <span v-if="scope.row.orderState == '01'">托管中</span>
                <span v-if="scope.row.orderState == '02'">托管结束</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="80">
              <template slot-scope="scope">
                <el-link @click="showDetail(2, scope.row)" type="primary">详情</el-link>
              </template>
            </el-table-column>
          </el-table>
          <div class="pages">
            <el-pagination background layout="total, prev, pager, next" :current-page="params.pnum"
              :page-size="params.psize" :total="total" @current-change="currentChange">
            </el-pagination>
          </div>
        </div>
      </div>

      <!-- 地图 -->
      <!-- <card title="服务网点">
        <div class="map-area">
          <baiduMap :projectList="list" ref="bmMap" />
        </div>
      </card> -->
    </div>

    <el-dialog title="供应信息详情" :visible.sync="dialogVisible" width="30%">
      <el-form ref="form" label-width="160px">
        <el-form-item label="农服商名称:">
          {{ currentItem.companyName }} <span v-if="currentItem.city">({{ currentItem.city }})</span>
        </el-form-item>
        <el-form-item label="经营场所地址:">
          {{ currentItem.address }}
        </el-form-item>
        <el-form-item label="联系人:">
          {{ currentItem.contactMan }}
        </el-form-item>
        <el-form-item label="联系电话:"> {{ currentItem.phone }} </el-form-item>
        <el-form-item label="意向区域:">{{
          currentItem.intentionRegion
          }}</el-form-item>
        <el-form-item label="作物类型:">
          {{ currentItem.serviceScope }}
        </el-form-item>
        <el-form-item label="托管环节:">
          {{ currentItem.operScope }}
        </el-form-item>
        <el-form-item label="可接管土地面积:">
          {{ currentItem.serviceArea }} 亩</el-form-item>
        <el-form-item label="已接管土地面积:">
          {{ currentItem.serviceAreaOlde }} 亩</el-form-item>
        <el-form-item label="经营场所照片:">
          <el-image style="width: 100px; height: 100px" :src="item" v-for="(item, index) in currentItem.companyPhoto"
            :key="index" :preview-src-list="currentItem.companyPhoto">
          </el-image>
        </el-form-item>
        <el-form-item label="现场照片:">
          <el-image style="width: 100px; height: 100px" :src="item" v-for="(item, index) in currentItem.xczp"
            :key="index" :preview-src-list="currentItem.xczp">
          </el-image>
        </el-form-item>
        <el-form-item label="其他说明:">{{ currentItem.remark }}</el-form-item>
      </el-form>
      <div class="btn" style="text-align: center">
        <el-button @click="back1">关闭</el-button>
        <el-button @click="collect1">收藏</el-button>
        <el-button @click="Online()">在线沟通</el-button>
        <el-button @click="goout(currentItem)" type="primary">邀请并导出合同</el-button>
      </div>
    </el-dialog>

    <el-dialog title="需求信息详情" :visible.sync="demandVisible" width="700px">
      <el-form :inline="true" label-width="160px">
        <el-form-item label="托管人:" style="width: 240px" v-if="currentItem.farmerName">
          {{
          currentItem.farmerName.substr(0, 1) +
          (currentItem.sex == "2" ? "女士" : "先生")
          }}
        </el-form-item>
        <el-form-item label="联系电话:">
          {{ currentItem.farmerPhone }}
        </el-form-item>
        <el-form-item label="托管类型:" style="width: 240px">
          {{ currentItem.orderType == 1 ? "全程托管" : "环节托管" }}
        </el-form-item>
        <el-form-item label="托管环节:" style="width: 100%"><span class="word">{{
            currentItem.orderContent
            }}</span></el-form-item>
      </el-form>
      <el-form ref="form" label-width="160px">
        <el-form-item label="作物类型:">{{
          currentItem.cropCode
          }}</el-form-item>
        <el-form-item label="每季保底产量:">{{ currentItem.seasonYield }}KG/亩</el-form-item>
        <el-form-item label="土地面积:">
          {{ currentItem.landArea }} 亩</el-form-item>
        <el-form-item label="地址:"> {{ currentItem.areaName }} </el-form-item>
        <el-form-item label="托管时间:">{{
          currentItem.startDate ? currentItem.startDate.slice(0, 10) : ""
          }}~{{
          currentItem.endDate ? currentItem.endDate.slice(0, 10) : ""
          }}</el-form-item>
        <el-form-item label="总价:">{{ currentItem.estimatePrice }}元</el-form-item>
        <el-form-item label="其他说明:">{{ currentItem.remark }}</el-form-item>

        <el-form-item label="现场照片:">
          <el-image style="width: 100px; height: 100px" :src="item" v-for="(item, index) in currentItem.xczp"
            :key="index" :preview-src-list="currentItem.xczp">
          </el-image>
        </el-form-item>
      </el-form>
      <div class="btn" style="text-align: center">
        <el-button @click="back2">关闭</el-button>
        <el-button @click="Online2">在线沟通</el-button>
        <el-button @click="collect">收藏</el-button>
        <el-button @click="goout2" type="primary">邀请接管并导出合同</el-button>
      </div>
    </el-dialog>
    <!-- <el-dialog
      title="合同预览"
      :visible.sync="showHt"
      width="1000px"
      custom-class="ht"
    >
      <div v-if="identityObj.identityTypeCode == 2">
        <span style="padding-left: 60px"
          >选择已发布托管土地:</span
        >
        <el-select
          @change="changeOrder"
          v-model="selectOrder"
          style="width: 400px"
        >
          <el-option
            v-for="(item, index) in orders"
            :key="index"
            :value="item.id"
            :label="item.Title"
            >{{ item.Title }}</el-option
          >
        </el-select>
      </div>
      <div class="content" ref="printContent">
        <pdf
          :src="pdfSrc"
          :page="pageNum"
          @progress="loadedRatio = $event"
          @page-loaded="pageLoaded($event)"
          @num-pages="pageTotalNum = $event"
          @error="pdfError($event)"
          @link-clicked="page = $event"
        >
        </pdf>
      </div>
      <div style="text-align: center">
        <el-button
          size="small"
          type="info"
          @click="prePage()"
          class="turn"
          :class="{ grey: pageNum == 1 }"
          >上一页</el-button
        >
        {{ pageNum }} / {{ pageAllNum }}
        <el-button
          size="small"
          type="info"
          @click="nextPage()"
          class="turn"
          :class="{ grey: pageNum == pageAllNum }"
          >下一页</el-button
        >
      </div>
      <p
        style="color: red; text-align: center"
        v-show="identityObj.identityTypeCode == 3"
      >
        *导出前请确认已与托管人沟通，并就托管事项达成一致
      </p>
      <div style="text-align: right">
        <el-button @click="showHt = false">取消</el-button
        ><el-button @click="exportHt" type="primary" v-if="currentCode == 1"
          >导出</el-button
        ><el-button @click="exportHt2" type="primary" v-if="currentCode == 2"
          >导出</el-button
        >
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
  const Query = [
    {
      name: "areaCode",
      label: "地区：",
      componentType: "cascader",
      placeholder: "请选择",
      width: "180px",
      props: {
        label: "areaName",
        children: "children",
        value: "id",
        checkStrictly: true,
        expandTrigger: "hover",
      },
      dataOptions: [],
    },
    {
      name: "intentionRegionCode",
      label: "意向区域：",
      componentType: "cascader",
      placeholder: "请选择",
      // noDataText:"请先选择地区",
      width: "180px",
      props: {
        label: "areaName",
        children: "children",
        value: "id",
        checkStrictly: true,
        expandTrigger: "hover",
      },
      dataOptions: [],
    },
    {
      name: "companyName",
      label: "农服商名称：",
      componentType: "input",
      placeholder: "",
      width: "140px",
      inputType: "",
    },
    // {
    //   name: "serviceType",
    //   label: "服务类型：",
    //   componentType: "select",
    //   placeholder: "请选择",
    //   width: "120px",
    //   dataOptions: [
    //     {
    //       name: "全程托管",
    //       value: 1,
    //     },
    //     {
    //       name: "环节托管",
    //       value: 2,
    //     },
    //   ],
    // },
    {
      name: "serviceScope",
      label: "作物类型：",
      componentType: "select",
      placeholder: "请选择",
      width: "140px",
      inputType: "",
      filterable: true,
    },
  ];
  const Query2 = [
    {
      name: "areaCode",
      label: "意向区域：",
      componentType: "cascader",
      placeholder: "请选择",
      width: "180px",
      props: {
        label: "areaName",
        children: "children",
        value: "id",
        checkStrictly: true,
      },
      dataOptions: [],
    },
    // {
    //   name: "orderType",
    //   label: "托管类型：",
    //   componentType: "select",
    //   placeholder: "请选择",
    //   width: "120px",
    //   dataOptions: [
    //     {
    //       name: "全程托管",
    //       value: 1,
    //     },
    //     {
    //       name: "环节托管",
    //       value: 2,
    //     },
    //   ],
    // },
    {
      name: "cropCode",
      label: "作物类型：",
      componentType: "select",
      placeholder: "请选择",
      width: "140px",
      inputType: "",
      filterable: true,
    },

    {
      name: "orderContent",
      label: "托管环节：",
      componentType: "select",
      placeholder: "请选择",
      width: "120px",
      dataOptions: [],
      filterable: true,
    },
  ];
  import { SendMessage, companyByid } from "@/api/websocket.js";
  import pdf from "vue-pdf";
  import Card from "@/components/Card/index.vue";
  import { getCompanyList, getOrderList } from "@/api/projectManage.js";
  import { getMachineryAll } from "@/api/supplyDemand.js";
  import { updateCollection, collectionLand } from "@/api/add.js";
  import {
    getXzqArr,
    // getCompanyCrop,
    collectionOrder,
    companyPusgPdf,
    orderPushPdf,
    getOrderNotPush,
    updateOrderState,
    updateCompanyState,
    getCropContent,
    getCompanyScopeAll,
    getDisticntCompanyCrop,
    // nhExportCheck,
    // companyExportCheck,
  } from "@/api/add";
  import HeTong from "./hetong.vue";
  import { removeEmptyChildren } from "@/js/utils/util";
  import FormQuery from "@/components/form/form-search";
  import BaiduMap from "../productionService/components/landTrustSubpage/baiduMap.vue";
  import { mapGetters } from "vuex";
  const newTab = [
    {
      code: 1,
      name: "托管服务商",
    },
    {
      code: 2,
      name: "托管地块",
    },
  ];

  export default {
    components: {
      Card,
      BaiduMap,
      FormQuery,
      pdf,
      HeTong,
    },
    computed: {
      ...mapGetters(["identityObj"]),
      topSelectAreacode: {
        get() {
          return this.$store.state.user.topSelectAreacode;
        },
        set(val) {
          return (this.$store.state.user.topSelectAreacode = val);
        },
      },
    },
    data() {
      return {
        baseUrl: window.globalUrl.HOME_API,
        zypzArr: [],
        form1Params: {},
        form2Params: {},
        htName: "",
        pdfSrc: "", // pdf文件src
        pageAllNum: 7, // pdf文件总页数
        pageNum: 1, // pdf文件页码
        selectOrder: "",
        orders: [],
        showHt: false,
        searchAreas: [],
        Query: Query,
        Query2: Query2,
        newTab,
        currentCode: 1,
        dialogVisible: false,
        demandVisible: false,
        keyForm: 1, //下拉框为请求来的数据时监听会用到
        serviceType: [
          {
            item: "1",
            name: "全程托管",
          },
          {
            item: "2",
            name: "环节托管",
          },
        ],
        regionOptions: [{}],
        props: {
          label: "xzqmc",
          children: "list",
          value: "xzqbh",
          checkStrictly: true,
          emitPath: false,
          expandTrigger: "hover",
        },
        options: [],
        statetion: [
          { item: "00", name: "未托管" },
          { item: "01", name: "托管中" },
          { item: "02", name: "托管结束" },
        ],
        options3: [],
        form1: {
          serviceScope: [],
          address: "",
          intentionRegion: "",
        },
        form2: {
          cropCode: [],
          orderContent: [],
          areaCode: "",
        },
        tableData: [],
        tableData1: [],
        total: 0,

        params: {
          pnum: 1,
          psize: 10,
        },
        list: [
          {
            id: "0",
            name: "宣汉县县运营公司名称",
            lot: 107.95,
            lat: 31.4,
          },
          {
            id: "1",
            name: "宣汉县测试公司名称",
            lot: 108,
            lat: 31.5,
          },
        ],

        currentItem: {},
        globalUrl: `${window.globalUrl.HOME_API_WEI}`,
        nongshiArr: [],
        zuowuArr: [],
      };
    },
    created() {
      this.zuowu();
      this.getRegions(); //【地区】获取的行政区
      this.nongshi();
      if (this.$route.query.currentCode) {
        //是否高亮第一个第二个
        this.currentCode = this.$route.query.currentCode * 1;
      }
      if (this.currentCode == 1) {
        //设置行政区 或者默认选中的
        setTimeout(() => {
          this.initFormQuery1();
        }, 200);
      }
      if (this.currentCode == 2) {
        setTimeout(() => {
          this.initFormQuery2();
        }, 200);
      }
    },
    mounted() {
      this.getData(this.currentCode);
      this.getMapData();
      // this.initFormQuery1()
      // this.initFormQuery2()
    },
    watch: {
      //监听到标题旁边的行政区变了 列表也刷新
      topSelectAreacode(val) {
        if (this.currentCode == 1) {
          this.initFormQuery1();
          this.getData(this.currentCode);
          this.areaChange({
            key: "areaCode",
            value: val,
          });
        } else {
          this.initFormQuery2();
          this.getData(this.currentCode);
        }
      },
    },

    methods: {
      async collect1() {
        console.log(this.currentItem);
        if (!this.identityObj.identityTypeCode) {
          this.$message.info("请登录后进行操作");
          return;
        }
        if (this.identityObj.identityTypeCode != 2) {
          this.$message.info("当前账户无权限，请切换农户账户后操作");
          return;
        }
        let res = await collectionLand({ companyServiceId: this.currentItem.id });
        if (res.code == 0) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      },
      //在线沟通
      // async allOnline(messageType, receiverType, receiver) {
      //   let content2 = JSON.stringify(this.currentItem);
      //   let params = {
      //     messageList: [
      //       {
      //         //消息类型会话托管
      //         messageType: messageType,
      //         content: content2,
      //       },
      //     ],
      //     receiverId: this.currentItem.authId,
      //     senderType: this.identityObj.identityTypeCode,
      //     receiverType: receiverType,
      //     senderId: this.identityObj.identityId,
      //     sender: this.identityObj.realName,
      //     receiver: receiver,
      //   };
      //   let res = await SendMessage(params);
      //   if (res.code !== 1) {
      //     this.$router.push({ path: "/home/Chat" });
      //   }
      // },
      // //托管服务商在线沟通
      // async Online() {
      //   this.allOnline(5, 4, this.currentItem.companyName);
      // },
      // //我有地，找接管在线沟通
      // async Online2() {
      //   this.allOnline(4, 2, this.currentItem.farmerName);
      // },

      async Online() {
        const jgdxq = await companyByid({
          id: this.currentItem.id,
        });
        console.log("接管单详情", jgdxq);
        console.log("供应信息详情111-Online");
        if (this.currentItem.authId == this.identityObj.identityId) {
          this.$message.info("本人项目");
        } else {
          const jgdxq = this.currentItem;
          let xczp = [];
          if (jgdxq.photos) {
            JSON.parse(jgdxq.photos).forEach((item) => {
              xczp.push(this.baseUrl + "admin/file/get?ossFilePath=" + item.url);
            });
          }
          jgdxq.xczp = xczp;

          let companyPhoto = [];
          if (jgdxq.companyPhoto == "" || jgdxq.companyPhoto == null) {
            companyPhoto = [];
          } else if (typeof jgdxq.companyPhoto === "string") {
            const arr = jgdxq.companyPhoto.split(",");
            arr.forEach((item) => {
              companyPhoto.push(
                this.baseUrl + "admin/file/get?ossFilePath=" + item
              );
            });
          } else {
            companyPhoto = jgdxq.companyPhoto;
          }
          jgdxq.companyPhoto = companyPhoto;

          console.log("接管单详情", jgdxq);

          let params = {
            messageList: [
              {
                //消息类型会话托管
                messageType: 5,
              },
            ],
            receiverId: this.currentItem.authId,
            senderType: this.identityObj.identityTypeCode,
            //接受消息人类型只有公司认证用户
            receiverType: 4,
            senderId: this.identityObj.identityId,
            sender: this.identityObj.realName,
            receiver: this.currentItem.companyName,
          };
          let res = await SendMessage(params);
          if (res.code !== 1) {
            this.$router.push({
              path: "/home/Chat",
              query: {
                isshow: true, // 你要传递的参数
                data: jgdxq,
                num: 1,
              },
            });
            // this.$router.push({ name: 'Chat', params: { isshow: true, data: jgdxq, num: 1 } });
          }
        }
      },

      async Online2() {
        if (this.currentItem.authId == this.identityObj.identityId) {
          this.$message.info("本人项目");
        } else {
          let content2 = JSON.stringify(this.currentItem);
          let params = {
            messageList: [
              {
                //消息类型会话接管
                messageType: 4,
                // content: content2,
              },
            ],
            receiverId: this.currentItem.authId,
            senderType: this.identityObj.identityTypeCode,
            //接受消息人类型只有个人认证用户
            receiverType: 2,
            senderId: this.identityObj.identityId,
            sender: this.identityObj.realName,
            receiver: this.currentItem.farmerName,
          };
          let res = await SendMessage(params);
          if (res.code == 0) {
            this.$router.push({
              path: "/home/Chat",
              query: {
                isshow: true, // 你要传递的参数
                data: this.currentItem,
                num: 3,
              },
            });

            // this.$router.push({ name: 'Chat', params: { isshow: true, data: this.currentItem, num: 2 } });
          }
        }
      },

      initFormQuery1() {
        let hasRegion = setInterval(() => {
      
          this.Query[0].dataOptions = this.searchAreas;
          this.Query[3].dataOptions = this.zuowuArr;
          console.log(this.zuowuArr,'作物')
          // console.log(this.Query[4].dataOptions, "作物")
          this.keyForm++;
          clearInterval(hasRegion);
        }, 300);
        if (this.topSelectAreacode) {
          setTimeout(() => {
            if (this.$refs.formquery1) {
              this.$refs.formquery1.changeFormItem(0, "areaCode", [
                this.topSelectAreacode,
              ]);
              this.areaChange({
                key: "areaCode",
                value: this.topSelectAreacode,
              });
              this.$refs.formquery1.changeForm(
                "areaCode",
                this.topSelectAreacode
              );
            }
          }, 300);
        }
      },
      initFormQuery2() {
        let hasRegion = setInterval(() => {
          this.Query2[0].dataOptions = this.searchAreas;
          this.Query2[1].dataOptions = this.zuowuArr;
          this.Query2[2].dataOptions = this.nongshiArr;

          this.keyForm++;
          clearInterval(hasRegion);
        }, 300);
        if (this.topSelectAreacode) {
          setTimeout(() => {
            if (this.$refs.formquery2) {
              this.$refs.formquery2.changeFormItem(0, "areaCode", [
                this.topSelectAreacode,
              ]);
              this.$refs.formquery2.changeForm(
                "areaCode",
                this.topSelectAreacode
              );
            }
          }, 300);
        }
      },
      async nongshi() {
        let arr = await getCompanyScopeAll();
        arr.data.forEach((item) => {
          item.label = item.categoryName;
          item.value = item.categoryName;
        });
        this.nongshiArr = arr.data;
      },
      async zuowu() {
        let arr = await getDisticntCompanyCrop();
        arr.data.forEach((item) => {
          item.label = item.categoryName;
          item.value = item.categoryName;
        });
        this.zuowuArr = arr.data;
      },
      async viewHt() {
        let params = {
          companyId: this.currentItem.id,
          orderId: this.selectOrder,
        };
        let res = await orderPushPdf(params);
        if (res) {
          const blob = new Blob([res], { type: "application/pdf;charset=utf-8" });
          this.pdfSrc = window.URL.createObjectURL(blob);
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      },
      // async viewHt2() {
      //   let params = {
      //     orderId: this.currentItem.orderId,
      //   };
      //   let res = await companyPusgPdf(params);
      //   if (res) {
      //     const blob = new Blob([res], { type: "application/pdf;charset=utf-8" });
      //     this.pdfSrc = window.URL.createObjectURL(blob);
      //   } else {
      //     this.$message({
      //       message: res.message,
      //       type: "error",
      //     });
      //   }
      // },
      async exportHt() {
        if (!this.selectOrder) {
          this.$message.info("请选择已发布的托管土地");
        }
        let params = {
          companyId: this.currentItem.id,
          orderId: this.selectOrder,
        };
        let res = await orderPushPdf(params);
        await updateOrderState(params);
        const blob = new Blob([res], { type: "application/pdf;charset=utf-8" });
        const url = window.URL.createObjectURL(blob);
        let dom = document.createElement("a");
        dom.style.display = "none";
        dom.href = url;
        dom.setAttribute("download", this.htName);
        document.body.appendChild(dom);
        dom.click();
      },
      async exportHt2() {
        let params = {
          orderId: this.currentItem.orderId,
          // orderId: "0469009792e7250132db2c693235ba50",
        };
        await updateCompanyState(params);
        let res = await companyPusgPdf(params);
        const blob = new Blob([res], { type: "application/pdf;charset=utf-8" });
        const url = window.URL.createObjectURL(blob);
        let dom = document.createElement("a");
        dom.style.display = "none";
        dom.href = url;
        let name =
          this.currentItem.areaName +
          (this.currentItem.orderType == 1 ? "全程托管" : "环节托管") +
          this.currentItem.cropCode +
          "共" +
          this.currentItem.landArea +
          "亩";
        dom.setAttribute("download", name);
        document.body.appendChild(dom);
        dom.click();
      },
      prePage() {
        var page = this.pageNum;
        page = page > 1 ? page - 1 : this.pageTotalNum;
        this.pageNum = page;
      },
      // 下一页函数
      nextPage() {
        var page = this.pageNum;
        page = page < this.pageTotalNum ? page + 1 : 1;
        this.pageNum = page;
      },
      changeOrder(id) {
        this.pageNum = 1;
        this.orders.forEach((item) => {
          if (item.id === id) {
            this.htName = item.Title;
          }
        });
        this.viewHt();
      },
      // async areaChange2(obj) {
      //   if (obj.key === "areaCode") {
      //     let res = await getXzqArr({
      //       level: 5,
      //       parentCode: obj.value,
      //     });
      //     if (res.code == 0) {
      //       this.$refs.formquery2.changeFormItem(
      //         1,
      //         "_dataOptions",
      //         removeEmptyChildren(res.data)
      //       );
      //     }
      //   }
      //   if (obj.key === "cropCode2") {
      //     let arr = [];
      //     obj.value.forEach((pz) => {
      //       this.zypzArr.forEach(async (item) => {
      //         if (item.categoryName == pz) {
      //           arr.push(item.id);
      //         }
      //       });
      //     });
      //     let arr3 = await getCropContent(arr.join(","));
      //     arr3.data.forEach((item) => {
      //       item.label = item.categoryName;
      //       item.value = item.categoryName;
      //     });
      //     this.$refs.formquery2.changeFormItem(
      //       4,
      //       "_dataOptions",
      //       removeEmptyChildren(arr3.data)
      //     );
      //   }
      // },
      async areaChange(obj) {
        if (obj.key === "areaCode") {
          let res = await getXzqArr({
            level: 5,
            parentCode: obj.value,
          });
          if (res.code == 0) {
            this.$refs.formquery1.changeFormItem(
              1,
              "_dataOptions",
              removeEmptyChildren(res.data)
            );
          }
        }
      },
      filerZwName(label) {
        this.zuowuArr.map((item) => {
          if (item.label === label) {
            this.loadCrop(item.id);
          }
        });
      },
      async loadCrop(id) {
        let res = await getCropContent(id);
        if (res.data && res.code == 0) {
          res.data.forEach((item) => {
            item.label = item.categoryName;
            item.value = item.categoryName;
          });
          this.$refs.formquery2.changeForm("orderContent", "");
          this.$refs.formquery2.changeFormItem(3, "_dataOptions", res.data);
        }
      },
      zwChange(obj) {
        // console.log(obj);
        if (obj.key === "cropCode") {
          console.log(this.filerZwName(obj.value));
        }
      },
      go() { },
      async collect() {
        if (!this.identityObj.identityTypeCode) {
          this.$message.info("请登录后进行操作");
          return;
        }
        if (this.identityObj.identityTypeCode != 4) {
          this.$message.info("当前账户无权限，请切换新型经营主体账户后操作");
          return;
        }
        let res = await collectionOrder({ orderId: this.currentItem.orderId });
        if (res.code == 0) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      },
      async getOrders() {
        let res = await getOrderNotPush();
        if (res.code == 0) {
          res.data.forEach((item) => {
            item.Title =
              item.areaName +
              (item.orderType == 1 ? "全程托管" : "环节托管") +
              item.cropCode +
              "共" +
              item.landArea +
              "亩";
          });
          this.orders = res.data;
        } else {
          this.orders = [];
        }
      },
      async viewHt() {
        let params = {
          companyId: this.currentItem.id,
          orderId: this.selectOrder,
        };
        // let params = {
        //   companyId: "349a840df89f8d7450596538668c8e28",
        //   orderId: "456461984564f5ds4f564s",
        // };
        let res = await orderPushPdf(params);
        if (res) {
          const blob = new Blob([res], { type: "application/pdf;charset=utf-8" });
          this.pdfSrc = window.URL.createObjectURL(blob);
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      },
      async goout(val) {
        console.log("7879", val);
        if (!this.identityObj.identityTypeCode) {
          this.$message.info("请登录后进行操作");
          return;
        }
        if (this.identityObj.identityTypeCode != 2) {
          this.$message.info("当前账户无权限，请切换农户角色后操作");
          return;
        }
        this.showHt = true;
        setTimeout(() => {
          this.$refs.hetong.selectOrder = "";
          this.$refs.hetong.getOrders(val);
        }, 100);
      },
      async goout2() {
        if (!this.identityObj.identityTypeCode) {
          this.$message.info("请登录后进行操作");
          return;
        }
        if (this.currentItem.orderState === "01") {
          this.$message.info("托管中，不支持导出合同");
          return;
        }
        if (this.currentItem.orderState === "02") {
          this.$message.info("托管结束，不支持导出合同");
          return;
        }
        if (this.identityObj.identityTypeCode != 4) {
          this.$message.info("当前账户无权限，请切换新型经营主体账户后操作");
          return;
        }
        this.showHt = true;
        setTimeout(() => {
          this.$refs.hetong.viewHt2(this.currentItem.orderId);
        }, 100);
      },
      submit() { },
      // 获取农机服务地图信息
      async getMapData() {
        let res = await getMachineryAll();
        if (!res || res.code != 0) {
          return;
        }
        this.list = [];
        res.data.forEach((ele) => {
          this.list.push({
            id: ele.id,
            name: ele.busName,
            lot: ele.longitude,
            lat: ele.latitude,
          });
        });
      },
      async getRegions() {
        // 获取地区信息
        let res = await getXzqArr({
          level: 5,
        });

        if (res.code == 0) {
          this.searchAreas = removeEmptyChildren(res.data);
          let item = this.$refs.formquery1?.formItems?.find(
            (item) => item.name == "areaCode"
          );
          let item1 = this.$refs.formquery2?.formItems?.find(
            (item) => item.name == "areaCode"
          );
          if (item) {
            item._dataOptions = this.searchAreas;
          }
          if (item1) {
            item1._dataOptions = this.searchAreas;
          }
        }
      },
      showDetail(type, item) {
        console.log("item", item);
        if (type == 1) {
          let xczp = [];
          if (item.photos) {
            JSON.parse(item.photos).forEach((item) => {
              xczp.push(this.baseUrl + "admin/file/get?ossFilePath=" + item.url);
            });
          }

          // 经营主体照片
          let companyPhoto = []; // 初始化一个空数组用于存储处理后的图片路径
          if (typeof item.companyPhoto === "string" && item.companyPhoto !== "") {
            // 如果 item.companyPhoto 是字符串类型
            const arr = item.companyPhoto.split(","); // 将字符串按逗号分割成数组
            arr.forEach((photoItem) => {
              // 修改此处的参数名，避免与外部的 item 冲突
              companyPhoto.push(
                this.baseUrl + "admin/file/get?ossFilePath=" + photoItem // 构造完整的图片路径并添加到数组中
              );
            });
          } else {
            companyPhoto = item.companyPhoto;
          }

          // else if (
          //   item.companyPhoto === "" ||
          //   item.companyPhoto[0] === "/api/admin/file/get?ossFilePath="
          // ) {
          //   // 如果 item.companyPhoto 是空字符串或者固定的路径字符串
          //   companyPhoto = []; // 直接将 companyPhoto 数组赋值为空数组
          // } else {
          //   // 如果 item.companyPhoto 是其他类型，可能是数组
          //   companyPhoto = Array.isArray(item.companyPhoto)
          //     ? item.companyPhoto
          //     : [item.companyPhoto]; // 考虑处理 item.companyPhoto 是数组的情况
          // }

          item.companyPhoto = companyPhoto; // 更新原始数据中的 companyPhoto 字段

          item.xczp = xczp;
          this.currentItem = item;
          this.dialogVisible = true;
        } else {
          let xczp = [];
          if (item.livePhotos) {
            JSON.parse(item.livePhotos).forEach((item) => {
              xczp.push(this.baseUrl + "admin/file/get?ossFilePath=" + item.url);
            });
          }
          item.xczp = xczp;
          let pzzp = [];
          if (item.landCertificateUrl) {
            JSON.parse(item.landCertificateUrl).forEach((item) => {
              pzzp.push(this.baseUrl + "admin/file/get?ossFilePath=" + item.url);
            });
          }

          // 经营主体照片
          // let companyPhoto = [];
          // if (typeof item.companyPhoto === "string") {
          //   const arr = item.companyPhoto.split(",");
          //   arr.forEach((item) => {
          //     companyPhoto.push(
          //       this.baseUrl + "admin/file/get?ossFilePath=" + item
          //     );
          //   });
          // } else if (item.companyPhoto == "") {
          //   companyPhoto = [];
          // } else if (item.companyPhoto == "/api/admin/file/get?ossFilePath=") {
          //   companyPhoto = [];
          // } else {
          //   companyPhoto = item.companyPhoto;
          // }
          // item.companyPhoto = companyPhoto;

          item.pzzp = pzzp;
          this.currentItem = item;
          this.demandVisible = true;
        }
      },
      back1() {
        this.dialogVisible = false;
      },
      back2() {
        this.demandVisible = false;
      },
      tabHandle(item) {
        this.currentCode = item.code;
        if (this.currentCode == 1) {
          setTimeout(() => {
            this.initFormQuery1();
          }, 200);
        }
        if (this.currentCode == 2) {
          setTimeout(() => {
            this.initFormQuery2();
          }, 200);
        }
        this.getData(item.code);
      },
      search(params) {
        console.log(params)
        
        this.form1Params = {
          // areaCode: params.areaCode
          //   ? params.areaCode[params.areaCode.length - 1]
          //   : null,
          // intentionRegionCode: params.intentionRegionCode
          //   ? params.intentionRegionCode[params.intentionRegionCode.length - 1]
          //   : null,
            areaCode: Array.isArray(params.areaCode) ? params.areaCode[params.areaCode.length - 1] : params.areaCode,
          intentionRegionCode: Array.isArray(params.intentionRegionCode) ? params.intentionRegionCode[params.intentionRegionCode.length - 1] : params.intentionRegionCode,
          companyName: params.companyName,
          serviceType: this.$route.query.code == "one" ? 1 : 2,
          serviceScope: params.serviceScope,
          pnum: 1,
        };
        this.searchFuc();
      },
      reset() {
        this.form1Params = {
           serviceType: this.$route.query.code == "one" ? 1 : 2,
        };
        this.params.pnum = 1;
        this.searchFuc();
      },
      search2(params) {
            console.log(params)
        this.form2Params = {
          // areaCode: params.areaCode
          //   ? params.areaCode[params.areaCode.length - 1]
          //   : null,
            areaCode: Array.isArray(params.areaCode) ? params.areaCode[params.areaCode.length - 1] : params.areaCode,

          orderType:this.$route.query.code == "one" ? 1 : 2,
          cropCode: params.cropCode,
          orderContent: params.orderContent,
          pnum: 1,
        };
        this.searchFuc2();
      },
      async searchFuc2() {
        this.params.pnum = 1;
        let res = await getOrderList({ ...this.params, ...this.form2Params });
        if (res.status) {
          this.tableData1 = res.data;
          // console.log(" this.tableData1", this.tableData1);
          this.total = res.dataNum;
        } else {
          this.$message.error("查询失败");
        }
      },
      reset2() {
        this.params.pnum = 1;
        this.form2Params = {
           orderType: this.$route.query.code == "one" ? 1 : 2,
        };
        
        this.searchFuc2();
      },
      async searchFuc() {
        let res = await getCompanyList({ ...this.params, ...this.form1Params });
        if (res.status) {
          this.tableData = res.data;
          this.total = res.dataNum;
        } else {
          this.$message.error("查询失败");
        }
      },

      async getData(code) {
        if (code == 1) {
          let res = await getCompanyList({
            ...this.params,
            areaCode: this.topSelectAreacode,
            serviceType: this.$route.query.code == "one" ? 1 : 2,
          });
          if (res.status == true) {
            this.tableData = res.data;
            this.total = res.dataNum;
          }
        } else {
          let res = await getOrderList({
            ...this.params,
            areaCode: this.topSelectAreacode,
             orderType: this.$route.query.code == "one" ? 1 : 2,
          });
          if (res.status == true) {
            this.tableData1 = res.data;
            this.total = res.dataNum;
          }
        }
      },
      currentChange(val) {
        this.params.pnum = val;
        this.getData(this.currentCode);
      },
    },
  };
</script>

<style lang="less" scoped>
  .land-trust {
    background: #ffffff;

    li {
      list-style: none;
    }
  }

  .quick-banner {
    display: flex;
    justify-content: center;
    /* 水平居中 */
    align-items: center;
    /* 垂直居中 */
    height: 700px;
    overflow: hidden;
  }

  .land-main {
    width: 1472px;
    margin: 0 auto;

    .s-bg {
      background: url("../../assets/icon-d-supply.png") no-repeat center center;
    }

    .new-list {
      padding: 30px 0;

      .news-tab {
        color: #999;
        font-size: 16px;
        display: flex;
        padding: 0px 0 20px 0px;

        .input-with-select {
          position: absolute;
          right: 0;
          top: -10px;
          width: auto;
        }

        li {
          padding: 8px 20px;
          font-size: 16px;
          font-weight: bold;
          background: #fff;
          margin-right: 12px;
          border: 2px solid #0668b3;
          cursor: pointer;
          color: #333;

          &:hover {
            color: #fff;
            background: #0668b3;
            border: 2px solid #0668b3;
          }

          &.active {
            color: #fff;
            background: #0668b3;
            border: 2px solid #0668b3;
          }
        }
      }

      .search {
        text-align: left;
        height: 60px;
      }

      /deep/.new-des {
        margin-top: 0;
      }
    }

    .pages {
      margin-top: 16px;
      text-align: right;
    }

    .news-des {
      margin-bottom: 20px;
    }

    .map-area {
      margin: 20px 0;
      height: 500px;
    }
  }

  .img {
    width: 400px;
  }
</style>

<style lang="less" scoped>
  .land-trust .el-table th {
    background: #0668b3;
    color: #fff;
  }

  .el-dialog {
    text-align: left;
  }

  .el-form-item {
    margin-bottom: 0px;
  }

  .content {
    width: 44%;
    margin: 10px auto;
    border: 1px solid #8b8383;
    min-height: 596px;
  }

  /deep/.ht {
    margin-top: 30px !important;
  }

  .word {
    display: inline-block;
    width: 400px;
  }

  .btn {
    margin-top: 30px;
  }

  /deep/.el-image {
    margin-right: 4px;
  }

  .text-container {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .two-line-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>